import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { viewProductCookies } from '../atoms/Header-Atoms'
import { useRecoilValue } from 'recoil';
import AlmondCookie from '../images/almond-cookie.png';
import ChocolateChipCookie from '../images/chocolate-chip-cookie.png';
import BakeryStyleChocolateChipCookie from '../images/bakery-style-chocolate-chip-ccokie.png';
import OatmealRaisinCookie from '../images/oatmeal-raisin-cookie.png';
import BakeryStyleGingerMolassesCookie from '../images/bakery-style-ginger-molasses-cookie.png';
import PeanutButterCookie from '../images/peanut-butter-cookie.png';
import MaineOatmealCookie from '../images/maine-oatmeal-cookies.png';
import AlmondChocolateChip from '../images/almond-chocolate-chip.png';

function ProductCookies() {
    const enabled = useRecoilValue(viewProductCookies);

    const handleClick = (e, param) => {
        var body = "";
        var subject = "";

        switch(param) {
            case 'almondCookie':
                subject = "Bleu Fox Bakery Online Order - Almond Cookie";
                body = "Thank you for your interest in the Almond Cookies. Please indicate the quantity you would like to order and we'll have someone contact shortly if within business hours.";
                break;
            case 'chocolateChipCookie':
                subject = "Bleu Fox Bakery Online Order - Homestyle Choclate Chip Cookie";
                body = "Thank you for your interest in the Homestyle Chocolate Chip Cookies. Please indicate the quantity you would like to order and we'll have someone contact shortly if within business hours.";
                break;
            case 'almondChocolateChip':
                subject = "Bleu Fox Bakery Online Order - Almond Choclate Chip Cookie";
                body = "Thank you for your interest in the Almond Chocolate Chip Cookies. Please indicate the quantity you would like to order and we'll have someone contact shortly if within business hours.";
                break;
            case 'bakeryStyleChocolateChipCookie':
                subject = "Bleu Fox Bakery Online Order - Bakery Style Choclate Chip Cookie";
                body = "Thank you for your interest in the Homestyle Chocolate Chip Cookies. Please indicate the quantity you would like to order and we'll have someone contact shortly if within business hours.";
                break;
            case 'oatmealRaisinCookie':
                subject = "Bleu Fox Bakery Online Order - Oatmeal Raisin Cookie";
                body = "Thank you for your interest in the Oatmeal Raisin Cookies. Please indicate the quantity you would like to order and we'll have someone contact shortly if within business hours.";
                break;
            case 'bakeryStyleGingerMolassesCookie':
                subject = "Bleu Fox Bakery Online Order - Bakery Style Ginger Molasses Cookie";
                body = "Thank you for your interest in the Homestyle Chocolate Chip Cookies. Please indicate the quantity you would like to order and we'll have someone contact shortly if within business hours.";
                break;
            case 'peanutButterCookie':
                subject = "Bleu Fox Bakery Online Order - Peanut Butter Cookie";
                body = "Thank you for your interest in the Peanut Butter Cookies. Please indicate the quantity you would like to order and we'll have someone contact shortly if within business hours.";
                break;
            case 'maineOatmealCookie':
                subject = "Bleu Fox Bakery Online Order - Maine Oatmeal Crunchies";
                body = "Thank you for your interest in the Maine Oatmeal Crunchies. Please indicate the quantity you would like to order and we'll have someone contact shortly if within business hours.";
                break;
            default:
                subject = "Bleu Fox Bakery Online Order - Products";
                body = "Thank you for your interest in our offerings. Please indicate the item(s) and quantity you would like to order and we'll have someone contact shortly if within business hours.";
                break;
        }

        var email="sales@bleufoxbakery.com";
        var mailto_link='mailto:'+email+'?subject='+subject+'&body='+body;
        window.open(mailto_link,'_self');
    };

    return (
        <Fade in={enabled} timeout={{ enter: 400, exit: 400 }}>
            <div id="section-3" className="contentModule">
                <Grid container spacing={1}>  
                    <Grid item xs={12}>
                        <Card sx={{ display: 'flex' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: '25%' }}
                                image={AlmondCookie}
                                alt=""
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="cardContent">
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6">
                                        Almond Cookie
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <p>This seemingly unassuming cookie may appear to lack excitement at first glance, yet a single bite will quickly have you craving more. The cookies offer a delightful almond flavor complemented by a subtle sweetness, featuring a crisp outer layer and a tender, slightly chewy center.</p>
                                        <p>They are an excellent accompaniment to a refined cup of tea, robust dark coffee, or even a straightforward glass of milk.</p>
                                        <p>Ingredients: Flour, Sugar, Almonds, Butter, Cornstarch, Brandy and Milk.</p>
                                        <p>Price: $6 USD (6 count), $9 USD (12 count)</p>
                                        <p><Button onClick={(e) => {handleClick(e, 'chocolateChipCookie');}} variant="outlined">Order</Button></p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>                  
                    <Grid item xs={12}>
                        <Card sx={{ display: 'flex' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: '25%' }}
                                image={ChocolateChipCookie}
                                alt=""
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="cardContent">
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6">
                                        Homestyle Chocolate Chip Cookie
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <p>Experience a touch of nostalgia with these freshly baked, soft, sweet, and delectable cookies. Their delightful flavor endures even the following day, provided they are stored in an airtight container, maintaining their tender texture. These cookies embody all the qualities one anticipates from an exceptional chocolate chip cookie.</p>
                                        <p>Ingredients: Flour, Butter, Brown Sugar, Sugar, Eggs, Chocolate and Dark Chocolate, Vanilla, Baking Soda, Cornstarch and Salt.</p>
                                        <p>Price: $5 USD (3 count), $8 USD (6 count), $12 USD (12 count)</p>
                                        <p><Button onClick={(e) => {handleClick(e, 'chocolateChipCookie');}} variant="outlined">Order</Button></p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{ display: 'flex' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: '25%' }}
                                image={BakeryStyleChocolateChipCookie}
                                alt=""
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="cardContent">
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6">
                                        Bakery Style Chocolate Chip Cookie
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <p>This is our rendition of a bakery-style chocolate chip cookie. It embodies the classic toffee-like flavor profile, enhanced by a combination of bittersweet and semi-sweet chocolate chunks. The cookie offers a pleasing chewy center, crisp edges, and a larger-than-average size.</p>
                                        <p>Ingredients: Flour, Butter, Chocolate, Brown Sugar, Sugar, Egg, Vanilla, Baking Soda, Baking Powder and Salt.</p>
                                        <p>Price: $2.50 USD (per cookie)</p>
                                        <p><Button onClick={(e) => {handleClick(e, 'peanutButterCookie');}} variant="outlined">Order</Button></p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{ display: 'flex' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: '25%' }}
                                image={OatmealRaisinCookie}
                                alt=""
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="cardContent">
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6">
                                        Oatmeal Raisin Cookie
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <p>A timeless delight. Our freshly baked Oatmeal Raisin cookies feature a tender center and crisp edges. Bursting with plump, juicy raisins and hearty rolled oats, these small cookies deliver an impressive flavor. Coated with powdered sugar and baked to perfection, they will undoubtedly leave you wanting more.</p>
                                        <p>Ingredients: Raisins, Rolled Oats, Flour, Sugar, Butter, Egg, Vegetable Shortening, Vanilla, Baking Soda, Salt, Molasses, Cinnamon and Baking Powder.</p>
                                        <p>Price: $5 USD (6 count), $9 USD (12 count)</p>
                                        <p><Button onClick={(e) => {handleClick(e, 'peanutButterCookie');}} variant="outlined">Order</Button></p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{ display: 'flex' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: '25%' }}
                                image={BakeryStyleGingerMolassesCookie}
                                alt=""
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="cardContent">
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6">
                                        Bakery Style Ginger Molasses Cookie
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <p>Featuring a chewy and soft consistency, these cookies are adorned with a crisp sugar coating. They present a sweet taste enriched by the warm spices of ginger and cloves. Those who appreciate ginger will find these cookies particularly enjoyable. While they will remain soft and chewy for a short duration, it is recommended to enjoy them within a few days.</p>
                                        <p>Ingredients: Flour, Butter, Sugar, Brown Sugar, Molasses, Egg, Baking Soda, Ginger, Cinnamon, Salt and Clove.</p>
                                        <p>Price: $2.50 USD (per cookie)</p>
                                        <p><Button onClick={(e) => {handleClick(e, 'peanutButterCookie');}} variant="outlined">Order</Button></p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{ display: 'flex' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: '25%' }}
                                image={PeanutButterCookie}
                                alt=""
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="cardContent">
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6">
                                        Peanut Butter Cookies
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <p>This is not an ordinary peanut butter cookie. We utilize natural peanut butter, which imparts a delightful sweetness to the cookies without overwhelming the palate, allowing the essence of peanut butter to be prominent. The cookies feature a soft, pillowy texture complemented by a subtle chewiness. You will find yourself wanting to order these in dozens, as it is quite challenging to consume just one.</p>
                                        <p>Ingredients: Flour, Natural Peanut Butter, Brown Sugar, Sugar, Butter, Cornstarch, Baking Soda, Baking Powder and Salt.</p>
                                        <p>Price: $5 USD (6 count), $8 USD (12 count)</p>
                                        <p><Button onClick={(e) => {handleClick(e, 'peanutButterCookie');}} variant="outlined">Order</Button></p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{ display: 'flex' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: '25%' }}
                                image={MaineOatmealCookie}
                                alt=""
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="cardContent">
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6">
                                        Maine Oatmeal Crunchies
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <p>A cherished family recipe passed down from "Great Aunt Rete," who prepared these delightful cookies for special occasions. These small yet extraordinary cookies are infused with a delightful toffee-like flavor and baked to achieve a perfect crispness. The addition of pecans enhances the overall taste, although we can prepare them without if preferred. For those who are not fond of oatmeal cookies, these may very well alter your perspective. Conversely, if you are an admirer of oatmeal cookies, these will undoubtedly satisfy your cravings.</p>
                                        <p>Ingredients: Oats, Brown Sugar, Flour, Sugar, Vegetable Oil, Pecans ( optional ), Egg, Baking Soda, Salt and Vanilla.</p>
                                        <p>Price: $6 USD (10 count), $10 USD (20 count)</p>
                                        <p><Button onClick={(e) => {handleClick(e, 'maineOatmealCookie');}} variant="outlined">Order</Button></p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{ display: 'flex' }}>
                            <CardMedia
                                component="img"
                                sx={{ width: '25%' }}
                                image={AlmondChocolateChip}
                                alt=""
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }} className="cardContent">
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography component="div" variant="h6">
                                        Almond Chocolate Chip Cookies
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" component="div">
                                        <p>These mildly sweet chocolate chip almond flour cookies offer an exceptional flavor experience. Featuring a chewy center, sweet and dark chocolate, crispy edges and a classic cookie texture, all with products that do not contain gluten!</p>
                                        <p>Ingredients: Almond Flour, Dark Chocolate, Brown Sugar, Butter, Egg, Vanilla, Cornstarch, Baking Soda and Salt.</p>
                                        <p>Price: $6 USD (3 count), $9 USD (6 count), $16 USD (12 count)</p>
                                        <p><Button onClick={(e) => {handleClick(e, 'almondChocolateChip');}} variant="outlined">Order</Button></p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        </Fade>      
    );
}

export default ProductCookies;